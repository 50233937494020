<template>
    <v-container class="w-container v-overflow fattAutomatica"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                color: white;
                                font-weight: bold;
                                padding: 4px;
                                font-size: 10px;
                                min-width:100vw">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_year" style="cursor:pointer">
                        <ion-item id="itemYear"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Anno </ion-label>

                            <ion-select id="year" name="year" class='year' cancel-Text="Chiudi" done-Text=""
                                :value="selAnno" interface="action-sheet">

                                <ion-select-option v-for="item in anni" :key="item.value" :value='item.value'> {{
                                    item.description
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_month" style="cursor:pointer">
                        <ion-item id="itemMonth"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Mese </ion-label>

                            <ion-select id="month" name="month" class='month' cancel-Text="Chiudi" done-Text=""
                                :value="selMese" interface="action-sheet">

                                <ion-select-option v-for="item in mesi" :key="item.value" :value='item.value'> {{
                                    item.desc_month
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_day" style="cursor:pointer">
                        <ion-item id="itemDay"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Giorno </ion-label>

                            <ion-select id="day" name="day" class='day' cancel-Text="Chiudi" done-Text=""
                                :value="selGiorno" interface="action-sheet">

                                <ion-select-option v-for="item in giorni" :key="item.day" :value='item.day'> {{
                                    item.day
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="selAzienda" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{
                                        item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>



            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_day" style="cursor:pointer">
                        <ion-item id="itemDay"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Tipo Budget </ion-label>

                            <ion-select id="budget" name="budget" class='budget' cancel-Text="Chiudi" done-Text=""
                                :value="selBudget" interface="action-sheet">

                                <ion-select-option v-for="item in tipo_budget" :key="item.id" :value='item.id'> {{
                                    item.description
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>



        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                        max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>

import apiinvoice from "../utils/invoice/apiinvoice";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        // console.log("PARAMS: ", this.$route.params.id);

        // console.log("GEN SETTINGS: ", window.$cookies.get("gen_settings").nuclei);

        // this.cur_id = this.$route.params.id;

        // da togliere
        // window.$cookies.set("token", "YWRtaW5fYw==", "9y");

        this.$root.$children[0].nomeAziendaSelezionata = "";

        this.$root.$children[0].checkLogout();



        var pointerVue = this;

        // pointerVue.$root.$children[0].normalDiv1 = true;
        // pointerVue.$root.$children[0].normalDiv2 = true;

        // pointerVue.$root.$children[0].invoiceDiv1 = false;
        // pointerVue.$root.$children[0].invoiceDiv2 = false;
        // pointerVue.$root.$children[0].invoiceDiv3 = false;
        // pointerVue.$root.$children[0].invoiceDiv4 = false;

        // pointerVue.$root.$children[0].curTitle = "Fatturazione Automatica";

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;
        pointerVue.$root.$children[0].invoiceDiv3Agila = false;

        pointerVue.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        pointerVue.$root.$children[0].curTitle = namesurname;
        pointerVue.$root.$children[0].invoiceTitle = "Fatturazione Automatica";

        this.setupButtons();

        setTimeout(() => {

            this.initParams();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/medici"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });


            $('body').on('ionChange', '.month', function (event) {
                console.log(event);

                pointerVue.selMese = this.value;

                pointerVue.syncDays(this.value);

                pointerVue.enableConfirmButton();


            });

            $('body').on('ionChange', '.year', function (event) {
                console.log(event);

                pointerVue.selAnno = this.value;

                pointerVue.enableConfirmButton();

            });

            $('body').on('ionChange', '.day', function (event) {
                console.log(event);

                pointerVue.selGiorno = this.value;

                pointerVue.enableConfirmButton();

            });

            $('body').on('ionChange', '.budget', function (event) {
                console.log(event);

                pointerVue.selBudget = this.value;


                pointerVue.enableConfirmButton();

            });

        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        tipo_budget: [{
            id: "P",
            description: "Previsto"
        }, {
            id: "R",
            description: "Reale"
        }],


        selAnno: null,
        selMese: null,
        selGiorno: null,
        selBudget: null,

        titolo: "",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",


        anni: [],
        mesi: [],
        giorni: [],

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {

        getLastDayMonth: function () {

            var date = new Date();

            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            var elements = lastDay.toString().split(" ");
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();


            return yyyy + "-" + mm + "-" + elements[2];


        },


        enableConfirmButton: function () {

            console.log("ANNO: ", this.selAnno);
            console.log("MESE: ", this.selMese);
            console.log("GIORNO: ", this.selGiorno);
            console.log("AZIENDA: ", this.selAzienda);
            console.log("BUDGET: ", this.selBudget);

            if ((this.selAnno != null) && (this.selMese != null) && (this.selGiorno != null) && (this.selAzienda != null) && (this.selBudget != null)) {
                this.hideShowButtons(true);
            }
            else {
                this.hideShowButtons(false);
            }


        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            // this.hideShowButtons(true);

            this.enableConfirmButton();

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: "https://app.emtool.eu/public/_lib/img/ok_easyer.png",
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Conferma",
                        width: 22
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/usr__NM__ico__NM__icon_ok_32.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    class: "inverted",
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {



            this.createInvoices();

        },


        createInvoices: async function () {

            var that = this;

            console.log("CREATE INVOICE ANNO: ", that.selAnno);
            console.log("CREATE INVOICE MESE: ", that.selMese);
            console.log("CREATE INVOICE GIORNO: ", that.selGiorno);
            console.log("CREATE INVOICE AZIENDA: ", that.selAzienda);
            console.log("CREATE INVOICE BUDGET: ", that.selBudget);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            if (that.selBudget == "P") { // budget previsto

                var response = await apiinvoice.validateAutomatic(
                    v_token,
                    that.selMese,
                    that.selGiorno,
                    that.selAzienda,
                    that.selAnno,
                    that.selBudget
                ).then(async (res) => {

                    console.log("res from validateAutomatic", res);


                    // if (res.data.Result == "OK") {

                    console.log("ok");


                    var response2 = await apiinvoice.createAutomatic(
                        v_token,
                        that.selMese,
                        that.selGiorno,
                        that.selAzienda,
                        that.selAnno,
                        that.selBudget
                    ).then((res2) => {

                        console.log(response2);

                        that.$root.$children[0].showProgress = false;
                        console.log("res from createAutomatic", res);


                        if (res2.data.Result == "OK") {


                            that.$swal({
                                icon: "success",
                                text: "Fatture create correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {


                                var lastDay = that.getLastDayMonth();

                                window.currentMonth = 0;
                                window.currentYear = new Date().getFullYear();

                                window.$cookies.set("is_from_scad", 0, "9y");
                                window.$cookies.set("is_from_edit_fatt", 0, "9y");
                                window.$cookies.set("is_from_suppliers", 0, "9y");


                                window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                                window.$cookies.set("sel_filter_inv_date_scad_to", lastDay, "9y");



                                // window.$cookies.set("sel_filter_inv_date_scad_from", "", "9y");
                                // window.$cookies.set("sel_filter_inv_date_scad_to", "", "9y");

                                window.$cookies.set("sel_filter_inv_date_emiss_from", "", "9y");
                                window.$cookies.set("sel_filter_inv_date_emiss_to", "", "9y");

                                window.$cookies.set("sel_filter_azienda_fatture", that.selAzienda, "9y");

                                router.push({
                                    path: "/fattureClienti"
                                });


                            }, 200);




                        }
                        else {

                            that.$root.$children[0].showProgress = false;

                            that.$swal({
                                icon: "error",
                                text: res2.data.Errore,
                                showConfirmButton: false,
                                timer: 2000
                            });



                        }






                    }).catch(err2 => {
                        that.$root.$children[0].showProgress = false;
                        console.log(err2);
                        var msg = err2.response.data.Error;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 8000
                        });
                        console.log("ERrori", "Non è stato possibile caricare i dati");
                        console.log("response", response);

                    }

                    );






                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

                );

            }


            if (that.selBudget == "R") // budget reale
            {



                window.$cookies.set("sel_mese_fatt_auto_reale", that.selMese, "9y");
                window.$cookies.set("sel_anno_fatt_auto_reale", that.selAnno, "9y");
                window.$cookies.set("sel_giorno_fatt_auto_reale", that.selGiorno, "9y");
                window.$cookies.set("sel_azienda_fatt_auto_reale", that.selAzienda, "9y");


                var desc_azienda = "";
                for (var x = 0; x < that.aziende.length - 1; x++) {

                    if (parseInt(that.selAzienda) == parseInt(that.aziende[x].companyid)) {

                        desc_azienda = that.aziende[x].fld_name;
                    }

                }



                window.$cookies.set("sel_desc_azienda_fatt_auto_reale", desc_azienda, "9y");


                router.push({
                    path: "/fatturazioneAutomaticaReale"
                });


            }





        },


        initParams: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.initParamsAutomatic(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initParamsAutomatic", res);

                this.aziende = res.data.company;

                this.anni = res.data.year;

                this.mesi = res.data.month;

                this.selAnno = res.data.cur_year;
                $("#itemYear").addClass("item-has-value");

                this.selMese = parseInt(res.data.cur_month);
                $("#itemMonth").addClass("item-has-value");


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncDays: async function (v_month) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getDays(
                v_token,
                v_month
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDays", res);


                that.giorni = res.data.days;


                that.selGiorno = res.data.last_day;
                $("#itemDay").addClass("item-has-value");


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }





    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container {
    width: 500px;
    padding: 0px;
}


.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.fattAutomatica .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.fattAutomatica .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.fattAutomatica .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.fattAutomatica .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.fattAutomatica ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
